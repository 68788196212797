<template>
  <b-row>
    <b-col>
      <b-card title="Actualizar usuarios">
        <b-row>
          <b-col md="3">
            <div class="form-group">
              <label>Nombre</label>
              <input
                v-model="form.nombre"
                type="text"
                class="form-control form-control-sm"
                placeholder="Nombre de usuario"
              />
            </div>
          </b-col>
          <b-col md="3">
            <div class="form-group">
              <label>N° de documento/Usuario</label>
              <input
                readonly
                v-model="form.usuario"
                class="form-control form-control-sm"
                placeholder="N° de documento"
              />
            </div>
          </b-col>
          <b-col md="3">
            <div class="form-group">
              <label>Contraseña</label>
              <input
                v-model="form.pass"
                type="password"
                class="form-control form-control-sm"
                autocomplete="off"
                autofocus="off"
              />
            </div>
          </b-col>
          <b-col md="3">
            <div class="form-group">
              <label>Perfil</label>
              <select
                class="form-control form-control-sm"
                v-model="form.id_nivel"
              >
                <option :value="null">Seleccione</option>
                <option v-for="(rol, idx) in roles" :key="idx" :value="rol.id">
                  {{ rol.name }}
                </option>
              </select>
            </div>
          </b-col>
          <b-col md="3">
            <div class="form-group">
              <label>Télefono</label>
              <input
                v-model="form.telefono"
                type="tel"
                class="form-control form-control-sm"
                placeholder="0000000000"
                maxlength="10"
              />
            </div>
          </b-col>
          <b-col md="3">
            <div class="form-group">
              <label>Correo</label>
              <input
                v-model="form.email"
                type="email"
                class="form-control form-control-sm"
                placeholder="example@email.com"
              />
            </div>
          </b-col>
          <b-col md="3">
            <transition name="fade">
              <div class="form-group" v-if="form.id_nivel == 6 || form.id_nivel == 5">
                <label>Contratista</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.codempresa"
                >
                  <option :value="null">Seleccione</option>
                  <option
                    v-for="(contrata, idx) in contratas"
                    :key="idx + '_C_'"
                    :value="contrata.codempresa"
                  >
                    {{ contrata.cliente }}
                  </option>
                </select>
              </div>
            </transition>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end">
              <router-link
                :to="{ name: 'users' }"
                class="btn btn-sm btn-circle btn-link mr-3"
              >
                <i class="la la-reply"></i> Regresar
              </router-link>
              <button
                class="btn btn-sm btn-circle btn-success"
                @click="update()"
              >
                <i class="flaticon-edit-1"></i>
                {{ loading ? "Cargando..." : "Actualizar" }}
              </button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import toastr from "toastr";
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export default {
  data() {
    return {
      id: this.$route.params.id,
      show: false,
      loading: false,
      form: {
        nombre: null,
        usuario: null,
        pass: null,
        id_nivel: null,
        codempresa: null,
        telefono: null,
        email: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      roles: "Roles/getRoles",
      contratas: "Contratas/getContratas",
    }),
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    reset() {
      this.form = {
        nombre: null,
        usuario: null,
        pass: null,
        id_nivel: null,
        codempresa: null,
        telefono: null,
        email: null,
      };
    },
    loadUser() {
      axios
        .get(`/users/${this.id}`)
        .then((res) => {
          const [data] = res.data;
          this.form = data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    update() {
      if (validateEmail(this.form.email) && this.form.email) {
        this.loading = true;
        if (this.form.codempresa) {
          const base = this.contratas.filter(
            (f) => f.codempresa == this.form.codempresa
          )[0];
          this.form.base = base.nombre;
        }
        this.form.id = this.id;
        if(this.form.usuario)
        {
          axios
            .put(`/users/${this.id}`, this.form)
            .then((res) => {
              if (res.data.message == "success") {
                this.loading = false;
                this.$swal.fire({
                  title: "Información",
                  text: "Registro actualizado...",
                  icon: "success",
                });
                this.loadUser()
              }
            })
            .catch((e) => {
              console.log(e);
              this.loading = false;
            });
        } else {
          toastr.info("El N° de documento es obligatorio");
        }
      } else {
        toastr.info("Asegurese de ingresar un correo válido");
      }
    },
  },
};
</script>
